import { useTranslation } from "react-i18next";
import Modal from "../../../modals/modal.component";
import { useEffect, useState } from "react";
import PasswordInput from "../../../password/password-input.component";
import { ErrorsType } from "../../../errors/errors.type";
import { ErrorsComponent } from "../../../errors/errors.component";
import axios from "axios";
import { environment } from "../../../../environment";
import { UserType } from "../../../../types/user.type";

export interface EditProfilePasswordProperties {
  success: boolean;
  setSuccess: (success: boolean) => void;
  setScreen: (screen: string) => void;
  setLoading: (loading: boolean) => void;
  userInfo: UserType;
}

export function EditProfilePasswordComponent(properties: EditProfilePasswordProperties): JSX.Element {
  const { t } = useTranslation();

  const [password, setPassword] = useState<string>('');

  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [submit, setSubmit] = useState<boolean>(false);

  const [errors, setErrors] = useState<ErrorsType | undefined>()

  const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>(false);

  useEffect(() => {
    if (password && repeatPassword && password !== repeatPassword) {
      setErrors({
        passwordsNotMatch: [t('form.errors.passwords')]
      })
      setPasswordsNotMatch(true)
    } else {
      setErrors(undefined)
      setPasswordsNotMatch(false)
    }
  }, [password, repeatPassword]);

  const onSubmit = () => {
    if (passwordsNotMatch) return;

    const errors: { password?: string[], repeatPassword?: string[] } = {};

    if (!password || password.trim() === '') {
      errors.password = [t('edit.profile.form.required')];
    }

    if (!repeatPassword || repeatPassword.trim() === '') {
      errors.repeatPassword = [t('edit.profile.form.required')];
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setSubmit(true);

    properties.setLoading(true);

    setErrors({});

    axios.put(`${environment.api.host}/api/user/edit-password/${properties.userInfo.id}`, {
      password: password,
    }, {
      headers: {
        Authorization: `Bearer ${properties.userInfo.access_token}`
      }
    }).then((response) => {
      setTimeout(() => {
        properties.setSuccess(true);
        properties.setLoading(false);
        setSubmit(false);
      }, 1000);
    }).catch((error) => {
      setTimeout(() => {
        properties.setLoading(false);

        const errors = error?.response?.data?.errors;

        if (errors) {
          setErrors(errors)
        } else {
          setErrors({ general: ['Error de conexión'] })
        }
      }, 1000);
    });
  }

  return <>
      <ErrorsComponent errors={errors} name="general" />
      <div
        className='Form'
        style={{
            paddingTop: 10,
        }}
      >
      {
        properties.success && <Modal
          isOpen={true}
          type='success'
          onClose={() => {
            properties.setSuccess(false)
            properties.setScreen('options')
          }}
          body={{
            title: t('edit.profile.password'),
            content: t('edit.profile.success')
          }}
        >

        </Modal>
        }
      <p>{t('restore.password')}</p>
      <PasswordInput
        name="password"
        providers={[ { name: 'local' } ]}
        placeholder={t('restore.password')}
        setPassword={setPassword}
        checkPassword={true}
        submitAttempt={submit}
        />
      <ErrorsComponent errors={errors} name="password" />
      <p>{t('restore.repeatPassword')}</p>
      <PasswordInput
        name="repeatPassword"
        providers={[ { name: 'local' } ]}
        placeholder={t('restore.repeatPassword')}
        setPassword={setRepeatPassword}
        />
      <ErrorsComponent errors={errors} name="repeatPassword" />
      <ErrorsComponent errors={errors} name="passwordsNotMatch" />
      <div className='Space' />
      <div className='Submit' onClick={onSubmit}>
        {t('form.update.title')}
      </div>
    </div>
  </>
}
