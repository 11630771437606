import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../modals/modal.component";
import { ErrorsType } from "../../../errors/errors.type";
import { ErrorsComponent } from "../../../errors/errors.component";
import { UserType } from "../../../../types/user.type";
import axios from "axios";
import { environment } from "../../../../environment";

export interface EditProfileNameProperties {
  success: boolean;
  setSuccess: (success: boolean) => void;
  setScreen: (screen: string) => void;
  userInfo: UserType;
  setLoading: (loading: boolean) => void;
  setUser: (user: UserType) => void;
}

export function EditProfileNameComponent(properties: EditProfileNameProperties): JSX.Element {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<ErrorsType>({})

  const [name, setName] = useState<string>(properties.userInfo.name);

  const [surnames, setSurnames] = useState<string>(properties.userInfo.surnames);

  const onSubmit = () => {
    const errors: { name?: string[], surnames?: string[] } = {};

    if (!name || name.trim() === '') {
      errors.name = [t('edit.profile.form.required')];
    }

    if (!surnames|| surnames.trim() === '') {
      errors.surnames = [t('edit.profile.form.required')];
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    properties.setLoading(true);

    setErrors({});

    axios.put(`${environment.api.host}/api/user/edit-profile/${properties.userInfo.id}`, {
      name: name,
      surnames: surnames
    }, {
      headers: {
        Authorization: `Bearer ${properties.userInfo.access_token}`
      }
    }).then((response) => {
      const responseWithToken = {
        ...response.data.items[0],
        access_token: properties.userInfo.access_token
      }

      properties.setUser(responseWithToken);

      setTimeout(() => {
        properties.setSuccess(true);
        properties.setLoading(false);
      }, 1000);
    }).catch((error) => {
      setTimeout(() => {
        properties.setLoading(false);

        const errors = error?.response?.data?.errors;

        if (errors) {
          setErrors(errors)
        } else {
          setErrors({ general: ['Error de conexión'] })
        }
      }, 1000);
    });
  }

  return <>
    <ErrorsComponent errors={errors} name="general" />
    <div
      className='Form'
      style={{
          paddingTop: 10,
      }}
    >
      {
        properties.success && <Modal
          isOpen={true}
          type='success'
          onClose={() => {
            properties.setSuccess(false);
            properties.setScreen('options');
          }}
          body={{
            title: t('edit.profile.name'),
            content: t('edit.profile.success')
          }}
        >

        </Modal>
        }
      <p>{t('edit.profile.edit.name')}</p>
      <div className="Input">
        <i className="las la-user"></i>
        <input
            type="text"
            placeholder=""
            onChange={(event) => {
              setName(event.target.value)
            }}
            value={name}
        />
      </div>
      <ErrorsComponent errors={errors}  name="name" />
      <p>{t('edit.profile.edit.surnames')}</p>
      <div className="Input">
        <i className="las la-user"></i>
        <input
          type="text"
          placeholder=""
          onChange={(event) => {
            setSurnames(event.target.value)
          }}
          value={surnames}
        />
      </div>
      <ErrorsComponent errors={errors} name="surnames" />
      <div className="Space" />
      <div className='Submit' onClick={onSubmit} >
              {t('form.update.title')}
      </div>
    </div>
  </>
}
