// Modules
import { useEffect, useRef, useState } from "react";

// Instances
import { DeviceInstance } from "../../functions/devices.instance";
import { SocketInstance } from "../../functions/socket.instance";

// Components
import { AudioComponent } from "./volumen.component";
import { useTranslation } from "react-i18next";
import { CreatorControlsButtonType } from "./creator.types";
import { UserSettingsHelper } from "../../../../helpers/UserSettingsHelper";
import { UserSettingsType } from "../../../../types/user_settings.type";
import DishMutedWidget from "../room/cameras/video/components/mutedWidget/mutedWidget.component";
import { audioInputDevices } from "../../functions/computer.detect";
import { use } from "i18next";
import { LoaderComponent } from "../../../loader/loader.component";

interface Props {
  stream?: MediaStream;
  deviceInstance: DeviceInstance;
  socketInstance: SocketInstance;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  userSettings: UserSettingsType | undefined;
  setAudioStatus: (status: boolean) => void;
  setVideoStatus: (status: boolean) => void;
  setLocalStreams: (streams: MediaStream[]) => void;
}

/**
 * Video Component
 * @param {Props} properties
 * @returns {JSX.Element}
 */
export const VideoComponent = (properties: Props): JSX.Element => {

  // translation function
  const { t } = useTranslation();

  // ref of video
  const ref = useRef<HTMLVideoElement>(null);

  // audio status
  const [audio, setAudio] = useState<boolean>();

  // video status
  const [video, setVideo] = useState<boolean>();

  // audio device id
  const [audioDeviceId, setAudioDeviceId] = useState<string>();

  // video device id
  const [videoDeviceId, setVideoDeviceId] = useState<string>();

  // audio devices
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);

  // video devices
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);

  // settings toggle
  const [settingsToggle, setSettingsToggle] = useState<boolean>(false);

  // video chanel name
  const [videoChanelName, setVideoChanelName] = useState<string>("");

  // audio chanel name
  const [audioChanelName, setAudioChanelName] = useState<string>("");

  // audio toggle
  const [audioToggle, setAudioToggle] = useState<boolean>(false);

  // video toggle
  const [videoToggle, setVideoToggle] = useState<boolean>(false);

  // list of backgrounds of asyncstorage
  const [backgrounds, setBackgrounds] = useState<string[]>([]);

  // background selected
  const [background, setBackground] = useState<string>();

  // have open gl
  const [haveOpenGl, setHaveOpenGl] = useState<boolean>(false);

  useEffect(() => {

    // get audio input devices
    if (properties.stream) {

      // get audio track
      const [audioTrack] = properties.stream.getAudioTracks();

      // is muted?
      if (audioTrack) {
        setAudio(audioTrack.enabled);
        properties.setAudioStatus(audioTrack.enabled);
      }

      // is video?
      const [videoTrack] = properties.stream.getVideoTracks();

      if (videoTrack) {
        setVideo(videoTrack.enabled);
        properties.setVideoStatus(videoTrack.enabled);
      }

      if (ref.current) {

        // add stream to video
        ref.current.srcObject = properties.stream;

      }

    }
  }, [properties.stream]);

  // get chanels of audio available
  const getAudioInputDevices = async () => {

    const devices = await properties.deviceInstance.getDevices({
      audio: true,
    });

    setAudioDevices(devices);

  };

  const getVideoInputDevices = async () => {

    const devices = await properties.deviceInstance.getDevices({
      video: true,
    });
    setVideoDevices(devices);

  };

  useEffect(() => {
    const handleDeviceChange = async () => {
      await getAudioInputDevices();
      await getVideoInputDevices();
    };

    // Llama a las funciones inicialmente
    handleDeviceChange();

    // Agrega el listener para el evento devicechange
    navigator.mediaDevices.ondevicechange = handleDeviceChange;

    // Limpia el listener cuando el componente se desmonte
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      properties.setLoading(false);
    }, 5000);
  }, []);

  const updateStream = async () => {

    properties.deviceInstance?.stopBackground()
    properties.setLoading(true);
    if (audioDeviceId) {
      // save in asyncstorage
      localStorage.setItem("audioDeviceId", audioDeviceId);

    }
    if (videoDeviceId) {
      // save in asyncstorage
      localStorage.setItem("videoDeviceId", videoDeviceId);
    }

    if (audioDeviceId && videoDeviceId) {

      await properties.socketInstance.removeLocalStreams();
      // if change audio device id or video device id

      const newStream = await properties.deviceInstance?.getDeviceMedia(
        {
          audioId: audioDeviceId,
          videoId: videoDeviceId,
        },
        {
          noise: true,
          echo: true,
          frameRate: 15,
          ptz: true,
        }
      );
      // get capabilities
      if (newStream && background) {

        // get track video
        const streamBackground =
          await properties.deviceInstance?.replaceBackground(
            newStream,
            background
          );

        streamBackground &&
          properties.socketInstance?.addLocalStream(streamBackground);
      } else {
        newStream && properties.socketInstance?.addLocalStream(newStream);
      }
    }
    else if (audioDeviceId) {

      await properties.socketInstance.removeLocalStreams();

      // if change audio device id or video device id
      const newStream = await properties.deviceInstance?.getDeviceMedia(
        {
          audioId: audioDeviceId,
        },
        {
          noise: true,
          echo: true,
          frameRate: 15,
        }
      );
      if (newStream) {
        properties.socketInstance?.addLocalStream(newStream);
      }
    }
    else if (videoDeviceId) {
      await properties.socketInstance.removeLocalStreams();
      // if change audio device id or video device id
      const newStream = await properties.deviceInstance?.getDeviceMedia(
        {
          videoId: videoDeviceId,
        },
        {
          noise: true,
          echo: true,
          frameRate: 15,
        }
      );



      if (newStream && background) {
        // get track video
        // create image base64 background black
        const imageBlack = new Image();
        imageBlack.src = background;

        const streamBackground =
          await properties.deviceInstance?.replaceBackground(
            newStream,
            imageBlack.src
          );

        streamBackground &&
          properties.socketInstance?.addLocalStream(streamBackground);
      } else {
        newStream && properties.socketInstance?.addLocalStream(newStream);
      }
    }


    properties.setLoading(false);




    setSettingsToggle(false);
  };

  const getBackgrounds = () => {
    // get backgrounds of asynstorage
    const backgrounds = localStorage.getItem("backgrounds");
    if (backgrounds) {
      setBackgrounds(JSON.parse(backgrounds));
    }
  };

  useEffect(() => {
    // check have opengl
    const canvas = document.createElement("canvas");
    const gl = canvas.getContext("webgl");
    if (gl) {
      setHaveOpenGl(true);
    }

    getBackgrounds();
  }, []);

  /**
     * Check if the string is a data uri base64 image
     *
     * @param str {string} The string to check
     * @returns  {boolean} If the string is a data uri base64 image
     */
  const isDataUriBase64Image = (str: string): boolean => {
    return /^data:image\/(png|jpg|jpeg|gif|svg\+xml);base64,/.test(str);
  }

  const renderButton = (settings: CreatorControlsButtonType): JSX.Element | undefined => {

    const classNames = [
      settings.className || 'button',
    ].join(' ').trim();

    const button = UserSettingsHelper.getUserSettingsButton(properties.userSettings, settings.name);

    let icon: string = (settings.active ? button?.icons?.active : button?.icons?.inactive) || settings.icon;

    const isDataUri = isDataUriBase64Image(icon);

    // If the button exists in the Hasura database, render it with the settings from the database
    if (button) {
      if (!button.visible) return;

      return isDataUri ?
        <img title={settings.title} src={icon} style={{ width: '38px' }} alt={settings.name} onClick={settings.onClick} /> :
        <i title={settings.title} className={icon} onClick={settings.onClick}></i>;
    }

    return isDataUri ?
      <img title={settings.title} src={icon} style={{ width: '38px' }} alt={settings.name} onClick={settings.onClick} /> :
      <i title={settings.title} className={icon} onClick={settings.onClick}></i>;
  }

  const setStreamInitial = async () => {
    const stream = properties.stream;
    if (stream) {
      const [audioTrack] = stream.getAudioTracks();
      const [videoTrack] = stream.getVideoTracks();
      if (audioTrack) {
        // audioDeviceId
        setAudioChanelName(audioTrack.label);
        setAudioDeviceId(audioTrack.getSettings().deviceId);

      }
      if (videoTrack) {

        // check if videotrack exist in videoDevices
        const videoAvailable = await properties.deviceInstance.getDevices({
          video: true,
        });

        if (videoAvailable) {
          const videoAvailableId = videoAvailable.filter((item) => {
            return item.deviceId === videoTrack.getSettings().deviceId;
          });
          if (videoAvailableId && videoAvailableId[0]?.label) {

            // videoDeviceId
            setVideoChanelName(videoAvailableId[0].label);
            setVideoDeviceId(videoAvailableId[0].deviceId);
          }
          else {
            // get async storage videoDeviceId
            const videoDeviceId = localStorage.getItem("videoDeviceId");
            if (videoDeviceId) {
              const videoAvailableId = videoAvailable.filter((item) => {
                return item.deviceId === videoDeviceId;
              });
              if (videoAvailableId) {
                // videoDeviceId
                setVideoChanelName(videoAvailableId[0].label);
                setVideoDeviceId(videoAvailableId[0].deviceId);
              }
            }
          }
        }
      }
    }
    // check asyncstorage have backgroundImage
    const backgroundImage = localStorage.getItem("backgroundImage");
    if (backgroundImage && !background) {
      setBackground(backgroundImage);
    }
  }
  useEffect(() => {
    setStreamInitial()

  }, [properties.stream]);
  return (
    <>

      <video
        ref={ref}
        muted={true}
        autoPlay
        playsInline
        //loading
        onLoadedData={() => {
          setTimeout(() => { }, 1000);
        }}
        onError={(e) => {
          console.error(
            "ERROR VIDEO #3"
          );
          console.error(e);
        }}
      />

      {/* Widgets */}
      {
        !audio && <DishMutedWidget />
      }

      {
        properties.loading && <LoaderComponent status={true} />
      }

      <div className="controls">
        {
          audio && properties.stream && <AudioComponent
            stream={properties.stream}
          />
        }


        {
          /* Creator Audio Toggle Button */
          properties.stream && renderButton({
            name: 'creator.audio',
            icon: audio ? 'las la-microphone' : 'las la-microphone-slash',
            title: t('button.video.audio.toggle.title').toString(),
            active: audio,
            onClick: () => {
              if (!properties.stream) return;
              const [audioTrack] = properties.stream.getAudioTracks();
              audioTrack.enabled = !audioTrack.enabled;
              setAudio(audioTrack.enabled);
              properties.setAudioStatus(audioTrack.enabled);
              properties.setLocalStreams([properties.stream]);
            },
          })
        }

        {
          /* Creator Video Toggle Button */
          properties.stream && renderButton({
            name: 'creator.video',
            icon: video ? 'las la-video' : 'las la-video-slash',
            title: t('button.video.toggle.title').toString(),
            active: video,
            onClick: () => {
              if (!properties.stream) return;
              const [videoTrack] = properties.stream.getVideoTracks();
              videoTrack.enabled = !videoTrack.enabled;
              setVideo(videoTrack.enabled);
              properties.setVideoStatus(videoTrack.enabled);
              properties.setLocalStreams([properties.stream]);
            },
          })
        }

        {
          /* Creator Settings Button */
          renderButton({
            name: 'creator.settings',
            icon: 'las la-cog',
            title: t('button.video.settings.title').toString(),
            onClick: () => {
              setSettingsToggle(!settingsToggle);
            },
          })
        }
      </div>
      {settingsToggle && (
        <div className="settings">
          <div className="settings-header">
            <div className="settings-close">
              <i
                className="las la-times"
                onClick={() => {
                  setSettingsToggle(!settingsToggle);
                }}
              ></i>
            </div>
            <div className="settings-title">
              <h2>{t('settings.title')}</h2>
            </div>
          </div>
          <p>{t('creator.micro.selector')}</p>
          { audioDevices.length > 0 && (
            <div
            className="select"
            onClick={() => {
              getAudioInputDevices();
              setAudioToggle(!audioToggle);
              setVideoToggle(false);
            }}
          >
            <span>
              <b>{audioChanelName || t('creator.micro.placeholder')}</b>
              <i
                className={
                  audioToggle ? "las la-angle-up" : "las la-angle-down"
                }
              ></i>
            </span>
            {audioToggle && (
              <div className="options">
                {audioDevices.map((device, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setAudioDeviceId(device.deviceId);
                        setAudioChanelName(device.label);
                      }}
                    >
                      {device.label}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          )
          }
          {
            (!audioDevices || audioDevices.length === 0) && (
              <div
                className="select"
              >
                <span>
                  <b>{t('creator.micro.no_devices')}</b>
                </span>
              </div>
            )
          }

          <p>{t('creator.video.selector')}</p>
          {
            videoDevices.length > 0 && (
              <div
            className="select"
            onClick={() => {
              getVideoInputDevices();
              setVideoToggle(!videoToggle);
              setAudioToggle(false);
            }}
          >
            <span>
              <b>{videoChanelName || t('creator.video.placeholder')}</b>
              <i
                className={
                  videoToggle ? "las la-angle-up" : "las la-angle-down"
                }
              ></i>
            </span>
            {videoToggle && (
              <div className="options">
                {videoToggle &&
                  videoDevices.map((device, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setVideoDeviceId(device.deviceId);
                          setVideoChanelName(device.label);
                        }}
                      >
                        {device.label}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
            )
          }
          {
            (!videoDevices || videoDevices.length === 0) && (
              <div
                className="select"
              >
                <span>
                  <b>{t('creator.video.no_devices')}</b>
                </span>
              </div>
            )
          }
          {
            videoDevices.length > 0 && haveOpenGl && <>
              <p>{t('creator.background.selector')}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignContent: "flex-start",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: 360,
                }}
              >
                {backgrounds.map((backgroundSelected, index) => {

                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setBackground(backgroundSelected);
                        // save too in localstorage
                        localStorage.setItem(
                          "backgroundImage",
                          backgroundSelected
                        );
                      }}
                      style={{
                        backgroundImage: `url(${backgroundSelected})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: 80,
                        height: 80,
                        borderRadius: 10,
                        margin: 10,
                        position: "relative",
                        outline:
                          backgroundSelected === background
                            ? "7px solid #aaa"
                            : "none",
                      }}
                    >
                      <i className="las la-times"
                        style={{
                          height: 30,
                          cursor: "pointer",
                          width: 30,
                          position: "absolute",
                          top: -10,
                          right: -10,
                          backgroundColor: "#eb4034",
                          color: "white",
                          borderRadius: 55,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={
                          (e) => {
                            e.stopPropagation();
                            if (backgrounds) {
                              setBackgrounds(backgrounds.filter((_: any, i: number) => i !== index))
                              setBackground(undefined)
                              // remove from localstorage
                              localStorage.removeItem("backgroundImage");

                              const backgroundsAsync = localStorage.getItem("backgrounds");
                              if (backgroundsAsync) {
                                const backgroundsArray = JSON.parse(backgroundsAsync);
                                localStorage.setItem(
                                  "backgrounds",
                                  JSON.stringify(
                                    backgroundsArray.filter((_: any, i: number) => i !== index)
                                  )
                                );
                              }
                            }
                          }
                        }></i>


                    </div>
                  );
                })}
                {backgrounds.length < 3 && (
                  <div
                    className="add"
                    style={{
                      width: 80,
                      margin: 10,
                      fontSize: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      height: 80,
                      borderRadius: 10,
                    }}
                    onClick={() => {

                      // create input file
                      const input = document.createElement("input");
                      input.type = "file";

                      // set accept image
                      input.accept = "image/*";

                      // emulate click
                      input.click();

                      input.onchange = async (e: any) => {

                        if (e?.target?.files?.[0]) {

                          // save image in asynstorage
                          const file = e.target.files[0];
                          const reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = async () => {

                            // compress image in 500 x 500 px
                            const base64 = reader.result;
                            const canvas = document.createElement("canvas");
                            const ctx = canvas.getContext("2d");
                            const img = new Image();
                            img.src = base64 as string;

                            img.onload = () => {

                              const width = 500;
                              const height = (img.height * width) / img.width;
                              canvas.width = width;
                              canvas.height = height;
                              ctx?.drawImage(img, 0, 0, width, height);
                              const base64 = canvas.toDataURL("image/jpeg");

                              const backgrounds = localStorage.getItem("backgrounds");
                              if (backgrounds) {
                                const backgroundsArray = JSON.parse(backgrounds);
                                backgroundsArray.push(base64);
                                setBackgrounds(backgroundsArray);
                                localStorage.setItem(
                                  "backgrounds",
                                  JSON.stringify(backgroundsArray)
                                );
                              } else {
                                setBackgrounds([base64]);
                                localStorage.setItem(
                                  "backgrounds",
                                  JSON.stringify([base64])
                                );
                              }
                              // set image in state
                              setBackground(base64);
                              // save in asyncstorage
                              localStorage.setItem("backgroundImage", base64);
                            };

                          };
                        }
                      };
                    }}
                  >
                    <i className="las la-plus"></i>
                  </div>
                )}
              </div>
            </>
          }
          <div className="Submit" onClick={updateStream}>
            {t('creator.update')}
          </div>
        </div>
      )}
    </>
  );
};

