// Types
import { useTranslation } from 'react-i18next';
import { AvailableType } from '../../dish.types';

// Properties
import { ParticipantsProperties } from './participants.properties';

// Styles
import './participants.styles.scss';
import { Fragment, useEffect, useState } from 'react';
import { UserType } from '../../../../types/user.type';
import axios from 'axios';
import { environment } from '../../../../environment';

export type ParticipantsType = {
  id: string;
  name: string;
  surname: string;
  email: string;
  smile?: boolean;
  color?: string;
  isAvailable?: boolean;
}

/**
 * Participants Component
 * @description Component to render participants of videocall
 * @param {ParticipantsProperties} properties
 * @returns {JSX.Element}
 */
export function ParticipantsComponent(properties: ParticipantsProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // Array of all users in the database for all user groups
    const [users, setUsers] = useState<ParticipantsType[]>([])

    const [clickedUsers, setClickedUser] = useState<string[]>([]);
    /**
     * Get all users that are in the user groups
     */
    const getUsers = async () => {
      const groupsIds = properties.user.users_groups.map(user_group => user_group.group.id).join(',');

      try {
        const response = await axios.get(`${environment.api.host}/api/user`, {
          headers: {
            Authorization: `Bearer ${properties.user.access_token}`
          },
          params: {
            groups: groupsIds
          }
        });

        if (!response.data.items) {
          setUsers([]);
          return;
        }

        // Map users to participants
        const userParticipants: ParticipantsType[] = response.data.items.map((user: UserType) => {
          return {
            id: user.id,
            name: user.name,
            surname: user.surnames,
            email: user.email,
            smile: false,
            color: 'gray',
            isAvailable: false
          } as ParticipantsType;
        })
        .filter((user: ParticipantsType) => user.id !== properties.user.id);

        if (properties.participants) {
          // Add participants to the users
          const participants = Object.values(properties.participants);

          participants.forEach(participant => {
            const user = userParticipants.find(user => user.id === participant.user.id);

            if (!user) {
              userParticipants.push({
                id: participant.user.id,
                name: participant.user.name,
                surname: participant.user.surnames,
                email: participant.user.email,
                smile: true,
                color: 'orange',
                isAvailable: false
              } as ParticipantsType);
            }
          });
        }

        setUsers(userParticipants);

      } catch (error) {
        console.error('[Login]: Error getting clients from API', error);

        setUsers([]);
      }
    }

    useEffect(() => {
      getUsers();
    }, [properties.participants]);

    useEffect(() => {
      setInterval(() => {
        console.log('Availables', properties.availables);
        console.log('Participants', properties.participants);
        console.log('Users', users);
      }, 5000);
    }, []);

    // call to user
    const callUser = (available: AvailableType) => {
        if (clickedUsers.includes(available.user.id)) return;
        if (available.color !== 'green') return;

        const newClickedUsers = [...clickedUsers, available.user.id];

        setClickedUser(newClickedUsers);

        properties.socket.emit('call:to', {
            available: available,
            room: properties.room,
        })
    }

    useEffect(() => {
      if (properties.rejectedCall) {
        setClickedUser(clickedUsers.filter(user => user !== properties.rejectedCall));
        properties.setUserRejectedCall('');
      }
    }, [properties.rejectedCall]);

    useEffect(() => {
      if (properties.acceptedCall) {
        setClickedUser(clickedUsers.filter(user => user !== properties.acceptedCall));
        properties.setUserAcceptedCall('');
      }
    }, [properties.acceptedCall]);

    // render user
    const renderUser = (participant: ParticipantsType) => {
        return <Fragment key={participant.id}>
            <i
                className={`${!participant.isAvailable ? 'las la-phone disconnected' : participant.smile ? 'las la-smile' : `las la-phone ${clickedUsers.includes(participant.id) ? 'animate-phone' : ''}`}`}
            />
            <div className="user">
                <div className="name">{participant.name} {participant.surname}</div>
            </div>
            <span style={{ backgroundColor: participant.color || 'green', }} />
        </Fragment>
    }

    // combine users with availability and participation status
    const combinedUsers = users.map(user => {
      const available = properties.availables.find(av => av.user?.id === user.id);
      const participant = Object.values(properties.participants).find(part => part.user?.id === user.id);

      return {
        ...user,
        smile: participant ? true : (available ? available.color === 'orange' : user.isAvailable),
        color: participant ? 'orange' : ( available ? available.color : 'gray' ),
        isAvailable: available && available.color === 'orange' ? true : (available ? true : false)
      }
    });

    // Order users by availability and participation status
    combinedUsers.sort((a, b) => {

      if (a.smile && !b.smile) {
        return -1;
      }

      if (!a.smile && b.smile) {
        return 1;
      }

      if (a.isAvailable && !b.isAvailable) {
        return -1;
      }

      if (!a.isAvailable && b.isAvailable) {
        return 1;
      }

      return 0;
    });

    return <div className="ParticipantsComponent Modal">
        <div className='Header'>
            {t('participants.title')}
            <i className='las la-times last' onClick={() => { properties.setScene(undefined) }}></i>
        </div>
        <ul>
            {combinedUsers.length > 0 ? combinedUsers.map((user) => {
                return <li key={user.id} onClick={() => {
                  const available = properties.availables.find(av => av.user?.id === user.id);

                  if (!available || user.smile) {
                    return;
                  }

                  callUser(available);
                }}>
                  {
                    renderUser(user)
                  }
                </li>
            }) : <div className="Empty">
                {t('participants.empty')}
            </div>}
        </ul>
    </div>
}
