// Modules
import { useEffect, useState } from "react";

// Styles
import './settings.dish.styles.scss'

// Properties
import { SettingsProperties } from "./settings.properties";
import { useTranslation } from "react-i18next";
import { LoaderComponent } from "../../../../../../../loader/loader.component";

/**
 * Dish Video Settings
 * @description Component to show video settings
 * @param {SettingsProperties} properties
 * @returns {JSX.Element}
 */
export function DishVideoSettings(properties: SettingsProperties) {

    // translation function
    const { t } = useTranslation();

    // audio output
    const [audioOutputStatus, setAudioOutputStatus] = useState<boolean>(false)

    // audio output devices
    const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([])

    // audio input
    const [audioInputStatus, setAudioInputStatus] = useState<boolean>(false)

    // audio input devices
    const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([])

    // video input
    const [videoInputStatus, setVideoInputStatus] = useState<boolean>(false)

    // video input devices
    const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([])

    // audio device id
    const [audioDeviceId, setAudioDeviceId] = useState<string>('');

    // audio input device id
    const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>('');

    // video device id
    const [videoDeviceId, setVideoDeviceId] = useState<string>('');

    // background selected
    const [background, setBackground] = useState<string>(localStorage.getItem('backgroundImage') || '');

    // backgrounds
    const [backgrounds, setBackgrounds] = useState<string[]>([]);

    // loading
    const [loading, setLoading] = useState<boolean>(false);

    // video settings
    const videoSettings = properties.stream.getVideoTracks().length > 0 ? properties.stream.getVideoTracks()[0].getSettings() : undefined;

    // audio settings
    const audioSettings = properties.stream.getAudioTracks().length > 0 ? properties.stream.getAudioTracks()[0].getSettings() : undefined;

    // stream settings
    const streamSettings: MediaStreamTrack = properties.stream.getVideoTracks().length > 0 ? properties.stream.getVideoTracks()[0] : properties.stream.getAudioTracks()[0];

    // render option
    const option = (name: string, icon: string, value: boolean | undefined | string | number, description?: string,) => {

        if (typeof value === 'string') {

            // split value
            if (value.length > 15) {

                value = value.substring(0, 15) + '...'

            }

        }
        return <div className="option">
            <i className={icon}></i>
            <span>{name} {description !== undefined && <b>{description} </b>}</span>
            <b>
                {
                    value === undefined && <i className='las la-times'></i>
                }
                {
                    typeof value === 'boolean' && value
                        ? <i className='las la-check'></i>
                        : typeof value === 'boolean' && !value
                            ? <i className='las la-times'></i>
                            : value
                }
            </b>
        </div>

    }

    useEffect(() => {
      const backgroundsAsync = localStorage.getItem("backgrounds");
      if (backgroundsAsync) {
        const backgroundsArray = JSON.parse(backgroundsAsync);
        setBackgrounds(backgroundsArray);
      }
    }, []);

    const [isBackgroundSelected, setIsBackgroundSelected] = useState<boolean>(localStorage.getItem('backgroundSelected') === 'true');

    useEffect(() => {
      const backgroundSelected = localStorage.getItem('backgroundImage');
      if (backgroundSelected) {
        setIsBackgroundSelected(true);
        localStorage.setItem('backgroundSelected', 'true');
      }
    }, []);

    const updateDevices = async () => {
      properties.setShowSettings();

      if (background) {
        localStorage.setItem('backgroundSelected', 'true');

        setIsBackgroundSelected(true);
      }

      properties.deviceInstance?.stopBackground();

      if (audioInputDeviceId) {
        localStorage.setItem('audioInputDeviceId', audioInputDeviceId)
      }

      if (videoDeviceId) {
        localStorage.setItem('videoDeviceId', videoDeviceId)
      }

      const streamId = properties.stream.id;

      if (audioInputDeviceId && videoDeviceId) {
        const newStream = await properties.deviceInstance?.getDeviceMedia(
          {
            audioId: audioInputDeviceId,
            videoId: videoDeviceId,
          },
          {
            noise: true,
            echo: true,
            frameRate: 15,
            ptz: true,
          }
        );

        if (newStream && background) {
          const streamBackground = await properties.deviceInstance?.replaceBackground(newStream, background);
          streamBackground && await properties.socketInstance.updateLocalStreams(streamId, streamBackground);

          setBackground(background);

          localStorage.setItem('backgroundImage', background);
        } else {
          newStream && await properties.socketInstance.updateLocalStreams(streamId, newStream);
        }

      } else if (audioInputDeviceId) {
        const newStream = await properties.deviceInstance?.getDeviceMedia(
          {
            audioId: audioInputDeviceId,
            videoId: properties.videoInputDevice
          },
          {
            noise: true,
            echo: true,
            frameRate: 15,
          }
        );

        if (newStream) {
          properties.socketInstance.updateLocalStreams(streamId, newStream);
        }

      } else if (videoDeviceId) {
        const newStream = await properties.deviceInstance?.getDeviceMedia(
          {
            videoId: videoDeviceId,
          },
          {
            noise: true,
            echo: true,
            frameRate: 15,
          }
        );

        if (newStream && background) {
          const imageBlack = new Image();
          imageBlack.src = background;

          const streamBackground = await properties.deviceInstance?.replaceBackground(newStream, background);
          streamBackground && await properties.socketInstance.updateLocalStreams(streamId, streamBackground);

          setBackground(background);

          localStorage.setItem('backgroundImage', background);
        } else {
          newStream && properties.socketInstance.updateLocalStreams(streamId, newStream);
        }
      }
    }

    const deleteBackground = () => {
      setBackground('');
      localStorage.removeItem('backgroundImage');
      setIsBackgroundSelected(false);
      localStorage.removeItem('backgroundSelected');
    }

    const setStreamInitial = async () => {
      const stream = properties.stream;

      if (stream) {
        const audioTrack = stream.getAudioTracks()[0];
        const videoTrack = stream.getVideoTracks()[0];

        if (audioTrack) {
          setAudioDeviceId(audioTrack.getSettings().deviceId || '');
          setAudioInputDeviceId(audioTrack.getSettings().deviceId || '');
        }

        if (videoTrack) {
          const videoAvailable = await properties.deviceInstance.getDevices({
            video: true,
          });

          if (videoAvailable) {
            const videoAvailableId = videoAvailable.filter((item) => {
              return item.deviceId === videoTrack.getSettings().deviceId;
            });
            if (videoAvailableId && videoAvailableId[0]?.label) {

              // videoDeviceId
              setVideoDeviceId(videoAvailableId[0].deviceId);
            }
            else {
              // get async storage videoDeviceId
              const videoDeviceId = localStorage.getItem("videoDeviceId");

              if (videoDeviceId) {
                const videoAvailableId = videoAvailable.filter((item) => {
                  return item.deviceId === videoDeviceId;
                });
                if (videoAvailableId) {
                  // videoDeviceId
                  setVideoDeviceId(videoAvailableId[0].deviceId);
                }
              }
            }
          }
        }
      }
    }

    useEffect(() => {

      setStreamInitial()

    }, [properties.stream]);

    return <>
        <div className="SettingsDishComponent" />

        <div className="settings">

            <div className='Header'>
                {t('settings.title')}
                <i className="las la-times last" onClick={() => {

                    properties.setShowSettings()

                }} />
            </div>
            <div className="layout" style={{
                top: 90
            }}>
              {
                    properties.getAudioOutputDevices && <>
                        <div className="box">
                            <div className='infor' >
                                <i className='las la-volume-up'></i>
                                {t('settings.audio.selector')}
                            </div>
                            <div className='selector'>
                                <div className='placeholder' onClick={async () => {

                                    setAudioOutputStatus(!audioOutputStatus)
                                    if (properties.getAudioOutputDevices) {
                                        const outputDevices = await properties.getAudioOutputDevices()
                                        setAudioOutputDevices(outputDevices || [])
                                    }


                                }}>
                                    <span>{properties.audioOutputDeviceLabel ? properties.audioOutputDeviceLabel : t('settings.audio.placeholder')}</span>
                                    <i className='las la-angle-down'></i>
                                </div>
                                {
                                    audioOutputStatus && <div className='chooses'>
                                        {
                                            audioOutputDevices.map((device, index) => {

                                                return <div className='choose' key={index} onClick={() => {

                                                    properties.setAudioOutputDevice(device.deviceId, device.label)
                                                    setAudioOutputStatus(false)
                                                    setAudioDeviceId(device.deviceId)

                                                }}>
                                                    {device.label}
                                                </div>

                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {
                    properties.getAudioInputDevices && <>
                        <div className="box">
                            <div className='infor' >
                                <i className='las la-microphone'></i>
                                {t('settings.audioInput.selector')}
                            </div>
                            <div className='selector'>
                                <div className='placeholder' onClick={async () => {

                                    setAudioInputStatus(!audioInputStatus)
                                    if (properties.getAudioInputDevices) {
                                        const inputDevices = await properties.getAudioInputDevices()
                                        setAudioInputDevices(inputDevices || [])
                                    }


                                }}>
                                    <span>{properties.audioInputDeviceLabel ? properties.audioInputDeviceLabel : t('settings.audio.placeholder')}</span>
                                    <i className='las la-angle-down'></i>
                                </div>
                                {
                                    audioInputStatus && <div className='chooses'>
                                        {
                                            audioInputDevices.map((device, index) => {

                                                return <div className='choose' key={index} onClick={() => {

                                                    properties.setAudioInputDevice(device.deviceId, device.label)
                                                    setAudioInputStatus(false)
                                                    setAudioInputDeviceId(device.deviceId)

                                                }}>
                                                    {device.label}
                                                </div>

                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {
                  properties.getVideoInputDevices && <>
                  <div className="box">
                      <div className='infor' >
                          <i className='las la-video'></i>
                          {t('settings.video.selector')}
                      </div>
                      <div className='selector'>
                          <div className='placeholder' onClick={async () => {

                              setVideoInputStatus(!videoInputStatus)
                              if (properties.getVideoInputDevices) {
                                  const inputDevices = await properties.getVideoInputDevices()
                                  setVideoInputDevices(inputDevices || [])
                              }


                          }}>
                              <span>{properties.videoInputDeviceLabel ? properties.videoInputDeviceLabel : t('settings.video.placeholder')}</span>
                              <i className='las la-angle-down'></i>
                          </div>
                          {
                              videoInputStatus && <div className='chooses'>
                                  {
                                      videoInputDevices.map((device, index) => {

                                          return <div className='choose' key={index} onClick={() => {

                                              properties.setVideoInputDevice(device.deviceId, device.label)
                                              setVideoInputStatus(false)
                                              setVideoDeviceId(device.deviceId)

                                          }}>
                                              {device.label}
                                          </div>

                                      })
                                  }
                              </div>
                          }
                      </div>
                  </div>
                  </>
                }
                {
                  <>
                  <div className="box">
                    <div className="infor">
                      <i className='las la-image'></i>
                      {t('settings.background.selector')}
                    </div>
                    <div className="selector">
                      {/* <input type="file" id="backgroundSelector" style={{display: 'none'}} accept="image/*" onChange={(e) => {
                        const file = e.target.files?.item(0);
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const result = e.target?.result;
                            if (typeof result === 'string') {
                              setBackground(result);
                              setBackgroundName(file.name);
                            }
                          }
                          reader.readAsDataURL(file);
                        }
                        }}

                      /> */}
                      {
                        isBackgroundSelected &&
                        <div className="placeholder" onClick={() => deleteBackground()}>
                          <i className='las la-file-image'></i>
                          <span>{t('settings.background.delete')}</span>
                          <i className={'las la-times'}></i>
                        </div>
                      }
                      <div>
                        {
                            <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignContent: "flex-start",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                width: 360,
                                marginTop: "10px",
                              }}
                            >
                                {backgrounds.map((backgroundSelected, index) => {

                                  return (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        setBackground(backgroundSelected);
                                        // save too in localstorage
                                        localStorage.setItem(
                                          "backgroundImage",
                                          backgroundSelected
                                        );
                                      }}
                                      style={{
                                        backgroundImage: `url(${backgroundSelected})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        width: 80,
                                        height: 80,
                                        borderRadius: 10,
                                        margin: 10,
                                        position: "relative",
                                        outline:
                                          backgroundSelected === background
                                            ? "7px solid #aaa"
                                            : "none",
                                      }}
                                    >
                                      <i className="las la-times"
                                        style={{
                                          height: 30,
                                          cursor: "pointer",
                                          width: 30,
                                          position: "absolute",
                                          top: -10,
                                          right: -10,
                                          backgroundColor: "#eb4034",
                                          color: "white",
                                          borderRadius: 55,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={
                                          (e) => {
                                            e.stopPropagation();
                                            if (backgrounds) {
                                              setBackgrounds(backgrounds.filter((_: any, i: number) => i !== index))
                                              setBackground('');
                                              // remove from localstorage
                                              localStorage.removeItem("backgroundImage");

                                              if (backgroundSelected === background) {
                                                setIsBackgroundSelected(false);
                                                localStorage.removeItem("backgroundSelected");
                                              }

                                              const backgroundsAsync = localStorage.getItem("backgrounds");
                                              if (backgroundsAsync) {
                                                const backgroundsArray = JSON.parse(backgroundsAsync);
                                                localStorage.setItem(
                                                  "backgrounds",
                                                  JSON.stringify(
                                                    backgroundsArray.filter((_: any, i: number) => i !== index)
                                                  )
                                                );
                                              }
                                            }
                                          }
                                        }></i>


                                    </div>
                                  );
                                })}
                                {backgrounds.length < 3 && (
                                  <div
                                    className="add"
                                    style={{
                                      width: 80,
                                      margin: 10,
                                      fontSize: 30,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                                      height: 80,
                                      borderRadius: 10,
                                    }}
                                    onClick={() => {

                                      // create input file
                                      const input = document.createElement("input");
                                      input.type = "file";

                                      // set accept image
                                      input.accept = "image/*";

                                      // emulate click
                                      input.click();

                                      input.onchange = async (e: any) => {

                                        if (e?.target?.files?.[0]) {

                                          // save image in asynstorage
                                          const file = e.target.files[0];
                                          const reader = new FileReader();
                                          reader.readAsDataURL(file);
                                          reader.onload = async () => {

                                            // compress image in 500 x 500 px
                                            const base64 = reader.result;
                                            const canvas = document.createElement("canvas");
                                            const ctx = canvas.getContext("2d");
                                            const img = new Image();
                                            img.src = base64 as string;

                                            img.onload = () => {

                                              const width = 500;
                                              const height = (img.height * width) / img.width;
                                              canvas.width = width;
                                              canvas.height = height;
                                              ctx?.drawImage(img, 0, 0, width, height);
                                              const base64 = canvas.toDataURL("image/jpeg");

                                              const backgrounds = localStorage.getItem("backgrounds");
                                              if (backgrounds) {
                                                const backgroundsArray = JSON.parse(backgrounds);
                                                backgroundsArray.push(base64);
                                                setBackgrounds(backgroundsArray);
                                                localStorage.setItem(
                                                  "backgrounds",
                                                  JSON.stringify(backgroundsArray)
                                                );
                                              } else {
                                                setBackgrounds([base64]);
                                                localStorage.setItem(
                                                  "backgrounds",
                                                  JSON.stringify([base64])
                                                );
                                              }
                                              // set image in state
                                              setBackground(base64);
                                              // save in asyncstorage
                                              localStorage.setItem("backgroundImage", base64);
                                            };

                                          };
                                        }
                                      };
                                    }}
                                  >
                                    <i className="las la-plus"></i>
                                  </div>
                                )}
                                </div>
                            </>

                          }
                      </div>
                    </div>
                  </div>
                  </>
                }
                {
                  <div className="btn-container">
                    <div className="Submit" onClick={updateDevices}>
                      {t('creator.update')}
                    </div>
                  </div>
                }


                {
                    // NOTE (Rafa): Not used since 2024-02-29
                    // videoSettings && <>
                    //     <div className='divisor'>{t('settings.video')}</div>
                    //     <div className="box">
                    //         {
                    //             videoSettings?.frameRate && option(t('settings.rate'), 'las la-clock', Math.round(videoSettings?.frameRate))
                    //         }
                    //         {
                    //             option(t('settings.aspect'), 'las la-crop', videoSettings?.aspectRatio?.toFixed(2))
                    //         }
                    //         {
                    //             option(t('settings.size'), 'las la-crop-alt', `${videoSettings?.height} x ${videoSettings?.width}`)
                    //         }
                    //     </div>
                    // </>
                }

                {
                    // NOTE (Rafa): Not used since 2024-02-29
                    // streamSettings && <>
                    //     <div className='divisor'>{t('settings.audio')}</div>
                    //     <div className="box">
                    //         {
                    //             option(t('settings.muted'), 'las la-microphone', !streamSettings?.muted)
                    //         }
                    //         {
                    //             option(t('settings.noiseSuppression'), 'las la-drum', audioSettings?.noiseSuppression)
                    //         }
                    //         {
                    //             option(t('settings.sampleRate'), 'las la-clock', audioSettings?.sampleRate || 'AUTO')
                    //         }
                    //         {
                    //             option(t('settings.sampleSize'), 'las la-history', audioSettings?.sampleSize)
                    //         }
                    //         {
                    //             option(t('settings.echoCancellation'), 'las la-assistive-listening-systems', audioSettings?.echoCancellation)
                    //         }
                    //         {
                    //             // @ts-ignore
                    //             option(t('settings.latency'), 'las la-tachometer-alt', audioSettings?.latency)
                    //         }
                    //         {
                    //             option(t('settings.autoGainControl'), 'las la-magic', audioSettings?.autoGainControl,)
                    //         }
                    //     </div>
                    // </>
                }
                {
                    // NOTE (Rafa): Not used since 2024-02-29
                    /* <div className='divisor'>{t('settings.stream')}</div>
                    <div className="box">
                        {
                            streamSettings && <>
                                {
                                    option(t('settings.enabled'), 'las la-plug', streamSettings?.enabled)
                                }
                                {
                                    option(t('settings.readyState'), 'las la-signal', streamSettings?.readyState)
                                }
                                {
                                    option(t('settings.kind'), 'las la-cog', streamSettings?.kind)
                                }
                                {
                                    option(t('settings.name'), 'las la-signature', streamSettings?.label)
                                }
                            </>
                        }
                    </div> */
                }
            </div>

        </div>
    </>

}
