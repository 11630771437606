import { useTranslation } from "react-i18next";
import { ErrorsComponent } from "../../../errors/errors.component";
import { ErrorsType } from "../../../errors/errors.type";
import { useState } from "react";
import Modal from "../../../modals/modal.component";
import { UserType } from "../../../../types/user.type";
import axios from "axios";
import { environment } from "../../../../environment";

export interface EditProfileSettingsProperties {
  success: boolean;
  setSuccess: (success: boolean) => void;
  setScreen: (screen: string) => void;
  userInfo: UserType;
  setLoading: (loading: boolean) => void;
  setUser: (user: UserType) => void;
}

export function EditProfileSettings(properties: EditProfileSettingsProperties): JSX.Element {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<ErrorsType>({});

  const [autoCall, setAutoCall] = useState<boolean>(properties.userInfo.user_setting.auto_call);

  const [autoCallTimeout, setAutoCallTimeout] = useState<number>(properties.userInfo.user_setting.auto_call_timeout);

  const onSubmit = () => {
    properties.setLoading(true);

    setErrors({});

    axios.put(`${environment.api.host}/api/user/edit-settings/${properties.userInfo.user_setting.id}`, {
      auto_call: autoCall,
      auto_call_timeout: autoCall ? autoCallTimeout !== 0 && autoCallTimeout !== 10 ? autoCallTimeout : 7 : 0,
      user_id: properties.userInfo.id
    }, {
      headers: {
        Authorization: `Bearer ${properties.userInfo.access_token}`
      }
    }).then((response) => {
      const responseWithToken = {
        ...response.data.items[0],
        access_token: properties.userInfo.access_token
      }

      properties.setUser(responseWithToken);

      setTimeout(() => {
        properties.setSuccess(true);
        properties.setLoading(false);
      }, 1000);
    })
    .catch((error) => {
      setTimeout(() => {
        properties.setLoading(false);

        const errors = error?.response?.data?.errors;

        if (errors) {
          setErrors(errors)
        } else {
          setErrors({ general: ['Error de conexión'] })
        }
      }, 1000);
    })
  }

  return <>
    <ErrorsComponent errors={errors} name="general" />
    <div
      className='Form'
      style={{
          paddingTop: 10,
      }}
    >
      {
        properties.success && <Modal
          isOpen={true}
          type='success'
          onClose={() => {
            properties.setSuccess(false);
            properties.setScreen('options');
          }}
          body={{
            title: t('edit.profile.others'),
            content: t('edit.profile.success')
          }}
        >

        </Modal>
        }
      <p>{t('edit.profile.settings.autoCall')}</p>
      <div className="Input">
        <i className="las la-phone"></i>
        <select className="dropdown-icon" onChange={(event) => {setAutoCall(event.target.value === 'true')}} defaultValue={String(autoCall)}>
          <option value="true">{t('edit.profile.settings.autoCall.on')}</option>
          <option value="false">{t('edit.profile.settings.autoCall.off')}</option>
        </select>
        <i className="las la-angle-down"></i>
      </div>
      <ErrorsComponent errors={errors}  name="autoCall" />
      {
        autoCall &&
        <>
        <p>{t('edit.profile.settings.autoCallTimeout')}</p>
        <div className="Input">
          <i className="las la-clock"></i>
          <select onChange={(event) => {setAutoCallTimeout(parseInt(event.target.value))}} defaultValue={autoCallTimeout}>
            <option value="7">{t('edit.profile.settings.autoCallTimeout.7')}</option>
            <option value="15">{t('edit.profile.settings.autoCallTimeout.15')}</option>
          </select>
          <i className="las la-angle-down"></i>
        </div>
        <ErrorsComponent errors={errors}  name="autoCallTimeout" />
        </>
      }
      <ErrorsComponent errors={errors}  name="name" />
      <div className='Space' />
      <div className='Submit' onClick={onSubmit}>
        {t('form.update.title')}
      </div>
    </div>
  </>
}
