// Modules
import { useEffect, useState } from "react";

// Components
import { VideoComponent } from "./video/dish.video.component"

// Properties
import { CamerasProperties } from "./cameras.properties";

// Styles
import './layout.styles.scss'
import './video/dish.video.styles.scss'
import DishVideoProfile from "./video/components/profile/dish.video.profile";
import { UserType } from "../../../../../types/user.type";
import { UserGroup } from "../../../../../types/users_groups";
import DishVideoRecording from "./video/components/recording/dish.video.recording";

/**
 * Cameras Component
 * @description Component to render cameras of videocall
 * @param properties
 * @returns
 */
export function CamerasComponent(properties: CamerasProperties) {

    // Layout Configuration
    const [videoBigSelected, setVideoBigSelected] = useState<string | undefined>(undefined);

    const [deviceDisconnected, setDeviceDisconnected] = useState<boolean>(false);

    const [showState, setShowState] = useState<boolean>(false);

    const [user, setUser] = useState<UserType|undefined>(undefined);

    const [totalParticipants, setTotalParticipants] = useState<number>(0);

    useEffect(() => {
      // Total Participants = Local Streams + Remote Streams
      let localParticipants = properties.localScreams ? properties.localScreams.length : 0;
      let remoteParticipants = 0;

      if (properties.remoteParticipants && Object.keys(properties.remoteParticipants).length > 0) {
        remoteParticipants = Object.keys(properties.remoteParticipants).reduce((acc, key) => {
          return acc + properties.remoteParticipants[key].streams.length;
        }, 0);
      }

      setTotalParticipants(localParticipants + remoteParticipants);

    }, [properties.localScreams, properties.remoteParticipants])

    const canRecordBasedOnGroups = (groups: UserGroup[]) => {
      return !groups.some((group) => !group.group.record_activated);
    }

    useEffect(() => {
      const localUser = localStorage.getItem('user');

      if (totalParticipants < 2 || Object.keys(properties.remoteParticipants).length === 0) {
        properties.setCanRecord(false);
        localStorage.setItem('canRecord', 'false');
        return;
      }

      if (localUser) {
        const groups = JSON.parse(localUser).users_groups;

        if (!canRecordBasedOnGroups(groups)) {
          properties.setCanRecord(false);
          localStorage.setItem('canRecord', 'false');
          return;
        }
      }

      for (const participant in properties.remoteParticipants) {
        const groups = properties.remoteParticipants[participant].user.users_groups;

        if (!canRecordBasedOnGroups(groups)) {
          properties.setCanRecord(false);
          localStorage.setItem('canRecord', 'false');
          return;
        }
      }

      properties.setCanRecord(true);
      localStorage.setItem('canRecord', 'true');
    }, [properties.remoteParticipants, properties.canRecord, totalParticipants]);

    // Set video big selected
    useEffect(() => {
        if (properties.layout === 'focus' && videoBigSelected === undefined) {
            properties.localScreams ? setVideoBigSelected('local-0') : setVideoBigSelected('user-0-0')
        }

    }, [properties, videoBigSelected])

    useEffect(() => {
        if (properties.localScreams && properties.localScreams.length === 0) {
            setShowState(true);
            properties.setAudioStatus(false);
            properties.setVideoStatus(false);
        }
    }, [properties.localScreams]);

    useEffect(() => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
          setUser(JSON.parse(storedUser));
      }
    }, []);

    return <div
        id='DishScenary'
        className={`ScenaryComponent  layout-${properties.layout}`}
    >
        <div className='canvas'>
            <div className={`cameras`}>
                {
                    showState && <DishVideoProfile
                      name={`${user?.name} ${user?.surnames ?? ''}`}
                      key={user?.id}
                      isDeviceProfile={true}
                      className="FixedSize-300"
                    />
                }
                {
                    properties.localScreams && properties.localScreams.map((stream, index) => (
                         <VideoComponent
                            type={properties.streamsTypeMap.get(stream.id) || 'local'}
                            totalParticipants={totalParticipants}
                            userSettings={properties.userSettings}
                            user={user}
                            setVideoBigSelected={() => setVideoBigSelected('local-' + index)}
                            getAudioOutputDevices={async () => await properties.deviceInstance.getAudioOutput()}
                            getVideoInputDevices={async () => await properties.deviceInstance.getVideoInput()}
                            getAudioInputDevices={async () => await properties.deviceInstance.getAudioInput()}
                            muted={true}
                            videoBigSelected={videoBigSelected === 'local-' + index}
                            layout={properties.layout}
                            key={stream.id}
                            name={`${user?.name} ${user?.surnames ?? ''}`}
                            stream={stream}
                            removeStream={() => properties.socketInstance.removeLocalStream(index)}
                            videoStatus={
                                index === 0 && properties.streamsTypeMap.get(stream.id) !== 'share.screen' ? properties.videoStatus : stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0].enabled : false
                            }
                            audioStatus={
                                index === 0 ? properties.audioStatus : stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0].enabled : false
                            }
                            socketInstance={properties.socketInstance}
                            deviceInstance={properties.deviceInstance}
                            setDeviceDisconnected={setDeviceDisconnected}
                            canRecord={properties.canRecord}
                            room={properties.room}
                        />
                    ))
                }
                {
                    properties.remoteParticipants && Object.keys(properties.remoteParticipants).length > 0 && Object.keys(properties.remoteParticipants).map((key, userIndex) => {
                      if (properties.remoteParticipants[key].streams.length === 0) {
                        return (
                        <DishVideoProfile
                          name={`${properties.remoteParticipants[key].user.name} ${properties.remoteParticipants[key].user.surnames ?? ''}`}
                          isDeviceProfile={true}
                          className="FixedSize-300"
                          key={properties.remoteParticipants[key].user.id}
                          />)
                      } else {
                        return properties.remoteParticipants[key]?.streams?.map((stream, streamIndex) => {
                          return (
                          <VideoComponent
                              userSettings={properties.userSettings}
                              totalParticipants={totalParticipants}
                              getAudioOutputDevices={async () => await properties.deviceInstance.getAudioOutput()}
                              getVideoInputDevices={async () => await properties.deviceInstance.getVideoInput()}
                              getAudioInputDevices={async () => await properties.deviceInstance.getAudioInput()}
                              videoBigSelected={videoBigSelected === `user-${userIndex}-${streamIndex}`}
                              setVideoBigSelected={() => setVideoBigSelected(`user-${userIndex}-${streamIndex}`)}
                              layout={properties.layout}
                              name={`${properties.remoteParticipants[key].user.name} ${properties.remoteParticipants[key].user.surnames ?? ''}`}
                              user={properties.remoteParticipants[key].user}
                              type={properties.streamsTypeMap.get(stream.id) || 'remote'}
                              removeStream={() => undefined}
                              key={streamIndex}
                              stream={stream}
                              videoStatus={
                                stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0].enabled : false
                              }
                              audioStatus={
                                stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0].enabled : false
                              }
                              setDeviceDisconnected={setDeviceDisconnected}
                              socketInstance={properties.socketInstance}
                              deviceInstance={properties.deviceInstance}
                              canRecord={properties.canRecord}
                              room={properties.room}
                          />
                        )});
                      }
                    })
                  }
            </div>
        </div>
    </div>
}
