import { useEffect, useState } from "react";

export function CountdownComponent({ time, onFinish }: { time: number, onFinish: () => void }): JSX.Element {
    const [countdown, setCountdown] = useState<number>(time);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(countdown - 1);
            if (countdown <= 1) {
                clearInterval(interval);
                onFinish();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countdown, onFinish])

    return <span style={
        {
            /* Color opacity */
            color: 'rgba(255, 255, 255, 0.8)',
            /* Padding left */
            paddingLeft: 10,
        }
    }>
        {countdown > 0 && countdown}
    </span>
}
