import './userInfo.styles.scss';


export interface UserInfoComponentProps {
  name: string;
}

export function UserInfoComponent(properties: UserInfoComponentProps) {
  return <>
    <div className="UserInfoComponent">
      {properties.name} <i className="las la-user-circle"></i>
    </div>
  </>
}
