export class LocalStorageHelper {

  /**
   * Remove all items from the local storage
   */
  static async cleanLocalStorage() {
    const localStorageItems = [
      'user',
      'userSettings',
      'canRecord',
      'audio_status',
      'video_status',
      'localCall',
      'audioDeviceId',
      'audioInputDeviceId',
      'videoDeviceId',
      'backgrounds',
      'backgroundImage',
    ];

    localStorageItems.forEach((item) => {
      localStorage.removeItem(item);
    });
  }

}
